import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1524589205/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`iOS SDK v2 Releases`}</h1>
    <h2>{`2.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Migrate to Enigmatic-Smile GitHub organization`}</li>
    </ul>
    <h2>{`2.1.2`}</h2>
    <ul>
      <li parentName="ul">{`Updated consent text for Mastercard cards.`}</li>
    </ul>
    <h2>{`2.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Add a few more card verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add card enrollment & verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Transaction Stream iOS SDK is now Stable!
We are thrilled to announce a significant milestone in our journey – the Transaction Stream SDK has officially transitioned from its beta phase to a stable release!
After months of rigorous testing, we are confident that Transaction Stream SDK is ready to empower your projects so you can create more valuable, engaging experiences for your customers.`}</li>
    </ul>
    <h2>{`2.0.0-beta20`}</h2>
    <ul>
      <li parentName="ul">{`Removed card scanning feature, please make sure to delete the `}<inlineCode parentName="li">{`Fidel.enableCardScanner`}</inlineCode>{` and `}<inlineCode parentName="li">{`Fidel.shouldAutoScanCard`}</inlineCode>{` configuration.`}</li>
    </ul>
    <h2>{`2.0.0-beta19`}</h2>
    <ul>
      <li parentName="ul">{`Improve the security of the SDK secrets.`}</li>
    </ul>
    <h2>{`2.0.0-beta18`}</h2>
    <ul>
      <li parentName="ul">{`Make the Fidel API requests ephemeral.`}</li>
    </ul>
    <h2>{`2.0.0-beta17`}</h2>
    <ul>
      <li parentName="ul">{`Updated the micro-charge descriptor text to the correct one.`}</li>
    </ul>
    <h2>{`2.0.0-beta16`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a bug that prevented corporate cardholders to delegate card verification to a third party, but later change their minds and verify the card (using the `}<inlineCode parentName="li">{`Fidel.verifyCard`}</inlineCode>{` function), on the same device.`}</li>
    </ul>
    <h2>{`2.0.0-beta15`}</h2>
    <ul>
      <li parentName="ul">{`Added Norway to the countries supported by the SDK.`}
        <ul parentName="li">
          <li parentName="ul">{`If you specified the `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property, remember to add Norway, to have it in the `}<inlineCode parentName="li">{`Country of issue`}</inlineCode>{` field.`}</li>
          <li parentName="ul">{`If you didn't customize the `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property, Norway will automatically show up in the `}<inlineCode parentName="li">{`Country of issue`}</inlineCode>{` field after you integrate the newest version of the SDK.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`2.0.0-beta14`}</h2>
    <ul>
      <li parentName="ul">{`Card scanning will now be disabled by default, use `}<inlineCode parentName="li">{`Fidel.enableCardScanner = true`}</inlineCode>{` to enable card scanning.`}</li>
    </ul>
    <h2>{`2.0.0-beta13`}</h2>
    <ul>
      <li parentName="ul">{`Useful features for corporate card enrollment, but not only:`}
        <ul parentName="li">
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`Fidel.thirdPartyVerificationChoice`}</inlineCode>{` property to allow cardholders to choose between verifying the card on the spot (as previously available) or indicate that the cardholder does not have access to the card statement and needs to delegate card verification to a third-party entity.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`Fidel.verifyCard`}</inlineCode>{` function to attempt card verification for a previously enrolled card. This function can be used for either the cardholder or a third-party entity (that cannot enroll cards, but can verify cards).`}</li>
          <li parentName="ul"><em parentName="li">{`(Experimental feature)`}</em>{` The `}<inlineCode parentName="li">{`Fidel.onCardVerificationChoiceSelected`}</inlineCode>{` callback that communicates the cardholder's choice and intention to either verify the card on the spot (because the cardholder has access to the card statement) or to express the intention to delegate card verification to a third-party entity.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Adds the `}<inlineCode parentName="li">{`Fidel.onCardVerificationStarted`}</inlineCode>{` callback which communicates that card verification has started for a card that was enrolled in a Fidel program. This callback provides the consent details that is created, in order to start card verification.`}</li>
      <li parentName="ul">{`Changes in the verification screen text that gives a better description on how the micro-charge will be displayed in the card statement`}</li>
      <li parentName="ul">{`Removes the currency symbol during the input of the verification token (the micro-charge amount).`}</li>
    </ul>
    <h2>{`2.0.0-beta12`}</h2>
    <ul>
      <li parentName="ul">{`This version displays better text to cardholders explaining that card verification micro-charges will be refunded within 72 hours.`}</li>
    </ul>
    <h2>{`2.0.0-beta11`}</h2>
    <ul>
      <li parentName="ul">{`Fixes error with Result not properly sent.`}</li>
    </ul>
    <h2>{`2.0.0-beta10`}</h2>
    <ul>
      <li parentName="ul">{`This version provides a comprehensive error message in case the micro-charge fails. You don't need to make any changes to your code in order to use this version.`}</li>
    </ul>
    <h2>{`2.0.0-beta9`}</h2>
    <ul>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`FidelResult.verificationSuccessful`}</inlineCode>{`. Replaced with `}<inlineCode parentName="li">{`FidelResult.verificationResult`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`We are now providing `}<inlineCode parentName="li">{`cardID`}</inlineCode>{` as part of the `}<inlineCode parentName="li">{`FidelResult.verificationResult`}</inlineCode>{`.`}</li>
    </ul>
    <h2>{`2.0.0-beta8`}</h2>
    <ul>
      <li parentName="ul">{`We've now blocked the SDK for cardholders that use insecure devices. If a cardholder has an insecure device, you'll receive the `}<inlineCode parentName="li">{`.deviceNotSecure`}</inlineCode>{` error via the `}<inlineCode parentName="li">{`onResult`}</inlineCode>{` closure/parameter that you can set. Please treat this error in the most appropriate way for your app.`}</li>
      <li parentName="ul">{`We stopped allowing copying card details from our SDK's input fields. Only the "Paste" operation is allowed.`}</li>
    </ul>
    <h2>{`2.0.0-beta7`}</h2>
    <p>{`Support card verification in GBP for UK issued cards.`}</p>
    <h2>{`2.0.0-beta6`}</h2>
    <p>{`We updated the "Fidel" name to "Fidel API".`}</p>
    <h2>{`2.0.0-beta5`}</h2>
    <p>{`We updated the Fidel logo.`}</p>
    <h2>{`2.0.0-beta4`}</h2>
    <p>{`We merged a few changes that we did in our public SDK:`}</p>
    <ol>
      <li parentName="ol">{`Added the `}<inlineCode parentName="li">{`defaultSelectedCountry`}</inlineCode>{` property which sets the country that will be selected by default, when opening the card enrollment screen.`}</li>
      <li parentName="ol">{`Removed the scanned card information confirmation screen. The card information is displayed immediately in the card enrollment screen after card scanning.`}</li>
      <li parentName="ol">{`Fixed the bug that does not allow enrollment for cards that expire in the current month.`}</li>
    </ol>
    <h2>{`2.0.0-beta3`}</h2>
    <ol>
      <li parentName="ol">{`If the user did not complete the card verification step (but did succeed to enroll a card) the SDK opens the card verification step when the app is re-opened. We made a few changes related to this process:`}
        <ul parentName="li">
          <li parentName="ul">{`Before opening the card verification step automatically, when the app is re-launched, we make sure that the Fidel SDK is configured correctly. If it is not configured correctly (it misses parameters or they are not valid) then you'll receive an appropriate error, if you're using the `}<inlineCode parentName="li">{`Fidel.onResult`}</inlineCode>{` closure. In case the SDK was not configured correctly, the card verification step will not be opened automatically.`}</li>
          <li parentName="ul">{`If the user did not complete the card verification step, closed the flow at this step, but does not quit your app and attempts to re-connect the card (by pressing on the button in your app), we'll take the user directly to the card verification step, to continue the process. Previously the user had to enroll the card again, which would have caused an error. By letting the user continuing the card verification process, we avoid errors in this scenario.`}</li>
        </ul>
      </li>
    </ol>
    <h2>{`2.0.0-beta2`}</h2>
    <ol>
      <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`present`}</inlineCode>{` function (which opened the Fidel card connection) UI to `}<inlineCode parentName="li">{`start`}</inlineCode>{`, in order to align with the web SDK.`}</li>
      <li parentName="ol">{`We made the `}<inlineCode parentName="li">{`companyName`}</inlineCode>{` property mandatory, as it is reflected in the consent text the user has to agree with before enrolling a card, so it made sense to make it mandatory. If the property is not set, you will not be able to open the Fidel card connection UI, but receive an error result immediately after the attempt to start the card connection process.`}</li>
      <li parentName="ol">{`We wanted to improve the SDK APIs to comply with the `}<a parentName="li" {...{
          "href": "https://www.swift.org/documentation/api-design-guidelines/"
        }}>{`Swift API design guidelines`}</a>{`:`}
        <ol parentName="li">
          <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`programId`}</inlineCode>{` property to `}<inlineCode parentName="li">{`programID`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Longer, more expressive names are fine, so we did the following renaming as well:`}
            <ol parentName="li">
              <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`autoScan`}</inlineCode>{` property to `}<inlineCode parentName="li">{`shouldAutoScanCard`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`privacyURL`}</inlineCode>{` property to `}<inlineCode parentName="li">{`privacyPolicyURL`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`termsConditionsURL`}</inlineCode>{` property to `}<inlineCode parentName="li">{`termsAndConditionsURL`}</inlineCode>{`.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property has been transformed from an `}<inlineCode parentName="li">{`Array`}</inlineCode>{`, to a `}<inlineCode parentName="li">{`Set`}</inlineCode>{`. If you allow multiple countries, they will now be sorted alphabetically.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ProgramType`}</inlineCode>{` enum now implements the `}<inlineCode parentName="li">{`Equatable`}</inlineCode>{` protocol (as all other enums made available by the Fidel SDK).`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`FidelAppDelegate`}</inlineCode>{` class has been removed. There is no need to make the `}<inlineCode parentName="li">{`Fidel.shared.applicationDidBecomeActive(_:)`}</inlineCode>{` function call in your `}<inlineCode parentName="li">{`AppDelegate`}</inlineCode>{` anymore.`}</li>
      <li parentName="ol">{`Removed `}<strong parentName="li">{`Objective-C support`}</strong>{` from our SDK in order to make our responses enums and structs which are more appropriate for what most iOS developers use: Swift.`}</li>
      <li parentName="ol">{`Improved the result entities and we are providing more types of results, during the card connection processes. In order to achieve this, we made a few changes to our SDK APIs:`}
        <ol parentName="li">
          <li parentName="ol">{`Removed the callback parameter from the `}<inlineCode parentName="li">{`start`}</inlineCode>{` function (previously named `}<inlineCode parentName="li">{`present`}</inlineCode>{`). The reason is that we introduced the flow to verify a card. If the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` that you set is `}<inlineCode parentName="li">{`.transactionStream`}</inlineCode>{` and if the user enrolls a card, but does not finish verifying the card, then the card verification screen will be re-opened the next time your app is opened, to make sure the user finishes card verification. That means that it is possible to receive card verification results or error results by starting this process without the user’s action. That’s why handling the result has been separated from the `}<inlineCode parentName="li">{`start`}</inlineCode>{` function.`}</li>
          <li parentName="ol">{`In order to handle a result, you have to set the `}<inlineCode parentName="li">{`Fidel.onResult`}</inlineCode>{` property. It can be called multiple times during a card connection flow. In order to receive results during the entire card connection process, please set the `}<inlineCode parentName="li">{`onResult`}</inlineCode>{` property in the `}<inlineCode parentName="li">{`application(_:didFinishLaunchingWithOptions:)`}</inlineCode>{` function, like all other Fidel configuration properties.`}</li>
          <li parentName="ol">{`The possible results that you can handle are `}<inlineCode parentName="li">{`FidelResult`}</inlineCode>{` enum cases. They are the following:`}
            <ol parentName="li">
              <li parentName="ol"><inlineCode parentName="li">{`enrollmentResult(EnrollmentResult)`}</inlineCode>{`; `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` was previously named `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`verificationSuccessful`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`error(FidelError)`}</inlineCode>{`.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{` class to `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Transformed the `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` into a struct.`}</li>
          <li parentName="ol"><inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` implements `}<inlineCode parentName="li">{`Equatable`}</inlineCode>{` protocol.`}</li>
          <li parentName="ol">{`Renamed a few properties of the `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{`, in order to comply with the `}<a parentName="li" {...{
              "href": "https://www.swift.org/documentation/api-design-guidelines/"
            }}>{`Swift API design guidelines`}</a>{`, and for some properties, we also changed their data types to be more suitable for these properties:`}
            <ol parentName="li">
              <li parentName="ol"><inlineCode parentName="li">{`id`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`cardID`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`programId`}</inlineCode>{` renamed to `}<inlineCode parentName="li">{`programID`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`accountId`}</inlineCode>{` renamed to `}<inlineCode parentName="li">{`accountID`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`created`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`enrollmentDate`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Changed data type of `}<inlineCode parentName="li">{`enrollmentDate`}</inlineCode>{` from `}<inlineCode parentName="li">{`String`}</inlineCode>{` to a `}<inlineCode parentName="li">{`Date`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`live`}</inlineCode>{` property to `}<inlineCode parentName="li">{`isLive`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardFirstNumbers`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`lastNumbers`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardLastNumbers`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`expYear`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardExpirationYear`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`expMonth`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardExpirationMonth`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`expDate`}</inlineCode>{` of type `}<inlineCode parentName="li">{`String`}</inlineCode>{` was removed, as we already provide `}<inlineCode parentName="li">{`cardExpirationYear`}</inlineCode>{` and `}<inlineCode parentName="li">{`cardExpirationMonth`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`scheme`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardScheme`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Changed the data type of `}<inlineCode parentName="li">{`cardScheme`}</inlineCode>{` from `}<inlineCode parentName="li">{`String`}</inlineCode>{` (which used internal scheme identifying strings as values) to `}<inlineCode parentName="li">{`CardScheme`}</inlineCode>{`, our custom data type that is easier to work with in Swift and that it was already used for the `}<inlineCode parentName="li">{`Fidel.supportedCardSchemes`}</inlineCode>{` property.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`countryCode`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`cardIssuingCountry`}</inlineCode></li>
              <li parentName="ol">{`Changed the data type of `}<inlineCode parentName="li">{`cardIssuingCountry`}</inlineCode>{` from `}<inlineCode parentName="li">{`String`}</inlineCode>{` (which used identifiers of countries as values) to `}<inlineCode parentName="li">{`Country`}</inlineCode>{`, our custom data type that is easier to work with in Swift and that it was already used for the `}<inlineCode parentName="li">{`Fidel.allowedCountries`}</inlineCode>{` property.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Changes in the `}<inlineCode parentName="li">{`LinkResultError`}</inlineCode>{` class:`}
            <ol parentName="li">
              <li parentName="ol">{`Renamed it to `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` as now it’s possible to receive errors during the card verification process, not just during the card enrollment process.`}</li>
              <li parentName="ol">{`Changed data type of the `}<inlineCode parentName="li">{`date`}</inlineCode>{` property from `}<inlineCode parentName="li">{`String`}</inlineCode>{` to a `}<inlineCode parentName="li">{`Date`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`code`}</inlineCode>{` property to `}<inlineCode parentName="li">{`type`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Changed the data type of the `}<inlineCode parentName="li">{`type`}</inlineCode>{` property from `}<inlineCode parentName="li">{`String`}</inlineCode>{` to `}<inlineCode parentName="li">{`FidelErrorType`}</inlineCode>{` , which is a new enum introduced to identify types of errors easily in Swift (please check this enum to see the types of errors that you can handle).`}</li>
            </ol>
          </li>
          <li parentName="ol"><inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` and `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` structs implement the `}<inlineCode parentName="li">{`Equatable`}</inlineCode>{` and `}<inlineCode parentName="li">{`Decodable`}</inlineCode>{` protocols.`}</li>
          <li parentName="ol">{`The only way possible to create an `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` and `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` instances is to decode them from our Fidel API responses (easier through our SDK).`}</li>
          <li parentName="ol">{`All properties of our result instances (of `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` & `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{`) are now immutable (`}<inlineCode parentName="li">{`let`}</inlineCode>{` constants).`}</li>
          <li parentName="ol">{`All properties of possible responses are correctly optional or non-optional`}</li>
          <li parentName="ol">{`It’s possible to handle errors with the type `}<inlineCode parentName="li">{`.userCanceled`}</inlineCode>{` during any stage of the card verification flow, if the user cancels it.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`2.0.0-beta1`}</h2>
    <ul>
      <li parentName="ul">{`Renamed the `}<inlineCode parentName="li">{`apiKey`}</inlineCode>{` property to `}<inlineCode parentName="li">{`sdkKey`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` property and the `}<inlineCode parentName="li">{`ProgramType`}</inlineCode>{` enum to specify the program type that you'll use in your app.`}</li>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` property is set to `}<inlineCode parentName="li">{`.transactionStream`}</inlineCode>{`, users will be able to start the card verification flow.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      